@if (state.userHasProPlan) {
  <div class="desktop-title text-primary-exam text-display-md mb-8 font-semibold">
    {{ 'settings.manage_subscription' | transloco }}
  </div>
  <h3 class="font-medium-exam text-primary-exam mb-1 text-lg">{{ 'settings.current_plan' | transloco }}</h3>
  <h3 class="text-md text-secondary mb-1">{{ 'settings.pro_subscription' | transloco }}</h3>
  @if (state.activeSubscription?.nextPaymentDate; as nextPaymentDate) {
    <div class="text-md text-secondary">
      {{ 'settings.valid_until' | transloco }} {{ nextPaymentDate | formatDate }}
    </div>
  }

  <a
    (click)="openCustomerPortal()"
    class="text-md mt-2 inline-flex font-semibold text-sky-500 hover:text-sky-400"
    [class.pointer-events-none]="state.customerPortalLoading"
  >
    {{ 'settings.change_plan' | transloco }}
  </a>
} @else {
  <div class="get-pro-bg mb-8 flex flex-wrap items-center gap-4 rounded-[1rem] p-6 lg:flex-nowrap">
    <div class="mx-auto h-[160px] w-[126px] shrink-0">
      <img src="assets/images/billing/sensei-estactic.svg" alt="'settings.get_pro' | transloco" />
    </div>
    <div class="text-neutral-0 flex flex-col gap-4 text-2xl font-semibold">
      <div [innerHTML]="'billing.pro_title' | transloco"></div>
      <dojo-button
        [fill]="'outline'"
        [customColor]="'indigo'"
        class="get-pro-button w-full"
        (click)="state.useBillingStepper ? openBillingStepperDialog() : openProPlanCheckout()"
        [pending]="state.checkoutSessionLoading"
      >
        {{ 'settings.get_pro' | transloco }}
      </dojo-button>
    </div>
  </div>
  <h2 class="text-primary-exam mb-6 text-2xl font-semibold">{{ 'settings.what_you_get' | transloco }}</h2>
  <div class="flex flex-col overflow-hidden rounded-[1rem] border border-neutral-200">
    @for (feature of features; track feature) {
      <div class="flex items-center gap-3 border-b border-neutral-200 p-4 last:border-none md:gap-6 md:p-8">
        <div class="aligner h-[56px] w-[56px] shrink-0">
          <img src="{{ feature.imagePath }}" alt="feature.text | transloco" />
        </div>
        <span class="font-primary text-lg font-semibold">{{ feature.text | transloco }}</span>
      </div>
    }
  </div>
}
