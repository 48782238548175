import { Injectable } from '@angular/core';
import { AbstractDialogService } from '@examdojo/core/dialog';
import { BillingStepperDialogComponent } from './billing-stepper-dialog.component';

export const BILLING_STEPPER_DIALOG_ID = 'billing-stepper-dialog';

export interface BillingStepperDialogOutput {
  subscribed: boolean;
}

@Injectable({ providedIn: 'root' })
export class BillingStepperDialogService extends AbstractDialogService<
  void,
  BillingStepperDialogOutput,
  typeof BillingStepperDialogComponent
> {
  protected readonly component = BillingStepperDialogComponent;

  protected override readonly options = {
    cssClass: 'fullscreen',
    showBackdrop: false,
    handle: false,
  };

  readonly id = BILLING_STEPPER_DIALOG_ID;
}
