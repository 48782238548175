import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from, map, Observable } from 'rxjs';
import { BillingHttpModel, BillingSubscription } from './billing.model';

export interface HostedBillingSession {
  result: {
    status: 'success';
    link: string;
  };
}

export interface EmbeddedBillingSession {
  result: {
    status: 'success';
    client_secret: string;
  };
}

@Injectable({ providedIn: 'root' })
export class BillingHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly supabaseService: ExamdojoSupabaseService,
  ) {}

  private readonly baseUrl = environment.examdojo.billingApiUrl;

  getHostedCheckoutSession() {
    return this.http.post<HostedBillingSession>(`${this.baseUrl}/checkout-session`, {
      ui_mode: 'hosted',
      success_url: window.location.href,
      cancel_url: window.location.href,
    });
  }

  getEmbeddedCheckoutSession(priceKey?: string) {
    return this.http.post<EmbeddedBillingSession>(`${this.baseUrl}/checkout-session`, {
      ui_mode: 'embedded',
      price_key: priceKey,
    });
  }

  getCustomerPortalSession() {
    return this.http.post<HostedBillingSession>(`${this.baseUrl}/customer-portal-session`, {
      return_url: window.location.href,
    });
  }

  fetchSubscriptions(): Observable<BillingSubscription[]> {
    return this.http
      .get<{
        result: {
          status: 'success';
          prices: Array<BillingSubscription & { lookup_key: string | null }>;
        };
      }>(`${this.baseUrl}/prices`)
      .pipe(map((response) => response.result.prices));
  }

  getActiveSubscription(userId: string): Observable<BillingHttpModel | null> {
    return from(
      this.supabaseService.client
        .from('user_subscriptions')
        .select()
        .eq('user_id', userId)
        .maybeSingle()
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
