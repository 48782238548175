<ion-content>
  <dojo-swiper-stepper
    [swiperOptions]="swiperOptions"
    class="billing-swiper"
    [submitFn]="confirmFn"
    [form]="form"
    #formStepper
  >
    <swiper-slide class="swiper-slide-background">
      <div class="right-container">
        <div class="flex min-h-full flex-col justify-start md:justify-center">
          <div class="mx-auto mb-6 max-w-[780px] md:mb-16">
            <ng-container *ngTemplateOutlet="slideHeader; context: { $implicit: 'billing.pro_title' | transloco }" />
          </div>

          <div class="mx-auto max-w-[500px]">
            <div class="pro-plan-table mb-6 md:mb-16">
              <table class="text-neutral-0 w-full" @staggeredAnimation>
                <tr>
                  <td></td>
                  <td>
                    <div class="free-row free-text">{{ 'billing.free' | transloco }}</div>
                  </td>
                  <td class="!w-4"></td>
                  <td class="pro-td" colspan="1" style="border-radius: 8px 8px 0 0">
                    <div class="pro-row pro-text">
                      {{ 'billing.pro' | transloco }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="py-[10px] pr-1">
                      {{ 'billing.unlimited_practice' | transloco }}
                    </div>
                  </td>
                  <td>
                    <div class="free-row">
                      <dojo-icon [icon]="'check'" [size]="21" />
                    </div>
                  </td>
                  <td class="!w-4"></td>
                  <td class="pro-td">
                    <div class="pro-row">
                      <dojo-icon [icon]="'check'" [size]="21" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="py-[10px] pr-1">
                      {{ 'billing.unlimited_grading' | transloco }}
                    </div>
                  </td>
                  <td>
                    <div class="free-row">
                      <dojo-icon [size]="16" style="color: rgba(255, 255, 255, 0.4)" [icon]="'minus'" [size]="21" />
                    </div>
                  </td>
                  <td class="!w-4"></td>
                  <td class="pro-td">
                    <div class="pro-row">
                      <dojo-icon [icon]="'check'" [size]="21" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="py-[10px] pr-1">
                      {{ 'billing.faster_grading' | transloco }}
                    </div>
                  </td>
                  <td>
                    <div class="free-row">
                      <dojo-icon [size]="16" style="color: rgba(255, 255, 255, 0.4)" [icon]="'minus'" [size]="21" />
                    </div>
                  </td>
                  <td class="!w-4"></td>
                  <td class="pro-td">
                    <div class="pro-row">
                      <dojo-icon [icon]="'check'" [size]="21" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="py-[10px] pr-1">
                      {{ 'billing.no_ads' | transloco }}
                    </div>
                  </td>
                  <td>
                    <div class="free-row">
                      <dojo-icon [size]="16" style="color: rgba(255, 255, 255, 0.4)" [icon]="'minus'" [size]="21" />
                    </div>
                  </td>
                  <td class="!w-4"></td>
                  <td class="pro-td" colspan="1" style="border-radius: 0 0 8px 8px">
                    <div class="pro-row">
                      <dojo-icon [icon]="'check'" [size]="21" />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <dojo-button fill="outline" swiperStepperNext [customColor]="'indigo'" class="slider-go-button w-full">
              {{ 'billing.get_pro' | transloco }}
            </dojo-button>
            <div class="mt-4 flex flex-col gap-6 md:mt-7">
              <!--              <button (click)="dismiss()" class="no-thanks-button">{{ 'billing.pay_later' | transloco }}</button>-->
              <button (click)="dismiss()" class="no-thanks-button">{{ 'billing.no_thanks' | transloco }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="left-container step-one">
        <dojo-icon-button
          [icon]="'xmark'"
          [transparent]="true"
          [iconSize]="20"
          [iconClass]="'text-neutral-0'"
          (click)="dismiss()"
          class="close-slider-button"
        ></dojo-icon-button>
      </div>
    </swiper-slide>

    <swiper-slide [controlName]="'productId'" class="swiper-slide-background">
      <div class="right-container">
        <div class="flex min-h-full flex-col justify-start md:justify-center">
          <div class="relative mx-auto mb-6 w-full max-w-[780px] md:mb-16">
            <ng-container
              *ngTemplateOutlet="slideHeader; context: { $implicit: 'billing.select_plan_title' | transloco }"
            />
          </div>
          <div class="mx-auto w-full max-w-[500px]">
            <dojo-selection-list
              class="billing-list mb-6 block md:mb-16"
              [formCtrl]="form.controls.productId"
              [options]="state.options"
              [multiple]="false"
              [labelsAreTranslationKeys]="false"
              [figureIconSize]="35"
              [hideIndicator]="true"
              [isDividedLabel]="true"
            />
            <dojo-button
              fill="outline"
              swiperStepperNext
              [customColor]="'indigo'"
              class="slider-go-button block w-full"
            >
              {{ 'continue' | transloco }}
            </dojo-button>
          </div>
        </div>
      </div>
      <div class="left-container step-next">
        <dojo-icon-button
          [icon]="'xmark'"
          [transparent]="true"
          [iconSize]="20"
          [iconClass]="'text-neutral-0'"
          (click)="dismiss()"
          class="close-slider-button"
        ></dojo-icon-button>
        <dojo-icon-button
          swiperStepperPrevious
          [hideOnFirstStep]="true"
          [icon]="'arrow-left'"
          [transparent]="true"
          [iconSize]="20"
          [iconClass]="'text-neutral-0'"
          class="arrow-back-button md:hidden"
        ></dojo-icon-button>
      </div>
    </swiper-slide>

    <swiper-slide class="swiper-slide-background">
      <div class="right-container">
        <div class="flex min-h-full flex-col justify-start md:justify-center">
          <div class="relative mx-auto mb-6 w-full max-w-[780px] md:mb-16">
            <ng-container
              *ngTemplateOutlet="slideHeader; context: { $implicit: 'billing.complete_order' | transloco }"
            />
          </div>
          <div class="relative mx-auto w-full max-w-[780px]">
            <div id="embedded-checkout" class="mb-6 max-w-full md:mb-16"></div>
          </div>
        </div>
      </div>
      <div class="left-container step-next">
        <dojo-icon-button
          [icon]="'xmark'"
          [transparent]="true"
          [iconSize]="20"
          [iconClass]="'text-neutral-0'"
          (click)="dismiss()"
          class="close-slider-button"
        ></dojo-icon-button>
        <dojo-icon-button
          swiperStepperPrevious
          [hideOnFirstStep]="true"
          [icon]="'arrow-left'"
          [transparent]="true"
          [iconSize]="20"
          [iconClass]="'text-neutral-0'"
          class="arrow-back-button md:hidden"
        ></dojo-icon-button>
      </div>
    </swiper-slide>

    <swiper-slide class="swiper-slide-background">
      <div class="last-step-bg flex min-h-full w-full flex-col justify-center">
        <div class="rays"></div>
        <div class="z-1 relative mx-auto max-w-[300px]">
          <dojo-image
            [src]="'assets/images/billing/sensei-estactic.svg'"
            [classes]="'mx-auto w-[190px] h-[240px] md:w-[300px] md:h-[381px] '"
            class="mb-6"
          ></dojo-image>
          <div class="text-neutral-0 mb-1 text-center text-lg font-semibold">
            {{ 'billing.welcome_pro' | transloco }}
          </div>
          <div class="text-md text-neutral-0 mb-6 text-center">{{ 'billing.you_can_manage' | transloco }}</div>
          <dojo-button [customColor]="'amber'" class="special-button block w-full overflow-hidden" swiperStepperNext>
            <span>{{ 'billing.start_learning_with_pro' | transloco }}</span>
            <span class="stripes">
              <span class="right-stripe"></span>
            </span>
          </dojo-button>
        </div>
      </div>
    </swiper-slide>

    <ng-template #slideHeader let-title>
      <div class="aligner gap-4">
        <dojo-icon-button
          swiperStepperPrevious
          [hideOnFirstStep]="true"
          [icon]="'arrow-left'"
          [transparent]="true"
          [iconSize]="20"
          [iconClass]="'text-neutral-0'"
          class="arrow-back-button absolute left-0 hidden md:flex"
        ></dojo-icon-button>
        <div class="slide-title px-8" [innerHTML]="title"></div>
      </div>
    </ng-template>
  </dojo-swiper-stepper>
</ion-content>
