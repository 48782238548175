import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { CurrencyPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnDestroy, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { connectState } from '@examdojo/angular/util';
import { ExamdojoAuthService } from '@examdojo/auth';
import { FormConfirmFn } from '@examdojo/core/form-submit-button';
import { InternationalizationService } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';
import {
  SwiperSlideDirective,
  SwiperStepperComponent,
  SwiperStepperNextDirective,
  SwiperStepperPreviousDirective,
} from '@examdojo/core/swiper';
import { ExamDojoFeatureFlag } from '@examdojo/models/feature-flag';
import { ButtonComponent } from '@examdojo/ui/button';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { ImageComponent } from '@examdojo/ui/image';
import { SelectionListComponent, SelectionListOption } from '@examdojo/ui/selection-list';
import { isNotNullish } from '@examdojo/util/nullish';
import { IonContent } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import { catchError, distinctUntilChanged, map, of, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { SwiperOptions } from 'swiper/types';
import { BillingService } from '../billing.service';
import { BillingStepperDialogService } from './billing-stepper-dialog.service';

@Component({
  selector: 'dojo-billing-stepper-dialog',
  imports: [
    IonContent,
    SwiperStepperComponent,
    SwiperSlideDirective,
    TranslocoPipe,
    SwiperStepperNextDirective,
    ButtonComponent,
    IconComponent,
    SelectionListComponent,
    IconButtonComponent,
    SwiperStepperPreviousDirective,
    NgTemplateOutlet,
    ImageComponent,
  ],
  templateUrl: './billing-stepper-dialog.component.html',
  styleUrl: './billing-stepper-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('staggeredAnimation', [
      transition('* => *', [
        // Initial state for all rows
        query('.pro-row', [style({ opacity: 0, transform: 'translateY(10px)', backgroundColor: 'transparent' })], {
          optional: true,
        }),
        query('.pro-td', [style({ backgroundColor: 'transparent' })], {
          optional: true,
        }),
        // Delay the entire animation by 2 seconds
        query('.free-row', [style({ opacity: 0, transform: 'translateY(10px)' })], { optional: true }),
        // Wait 2 seconds before starting animations
        query(
          '.free-row',
          [stagger(50, [animate('300ms 300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))])],
          { optional: true },
        ),
        // Then animate pro-rows after the free-rows are done
        query(
          '.pro-row',
          [stagger(50, [animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))])],
          { optional: true },
        ),
        // Then animate pro-rows to have a different background color
        query(
          '.pro-td',
          [stagger(50, [animate('300ms ease-out', style({ backgroundColor: 'rgba(255, 255, 255, 0.1)' }))])],
          { optional: true },
        ),
        // Finally, pulsate the pro-text elements
        query(
          '.pro-text',
          [
            animate('400ms 200ms ease-in-out', style({ transform: 'scale(1.2)' })),
            animate('400ms ease-in-out', style({ transform: 'scale(1)' })),
          ],
          { optional: true },
        ),
      ]),
    ]),
  ],
  providers: [CurrencyPipe],
})
export class BillingStepperDialogComponent implements OnDestroy {
  constructor(
    private readonly billingStepperDialogService: BillingStepperDialogService,
    private readonly stripeService: StripeService,
    private readonly billingService: BillingService,
    private readonly internationalizationService: InternationalizationService,
    private readonly currencyPipe: CurrencyPipe,
    private readonly authService: ExamdojoAuthService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.mountEmbeddedCheckoutFormWhenSelectedSubscriptionChanges().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly formStepperRef = viewChild<SwiperStepperComponent<FormGroup, void>>('formStepper');
  readonly formStepperRef$ = toObservable(this.formStepperRef);

  readonly activeSlideIndex$ = this.formStepperRef$.pipe(
    filter(Boolean),
    switchMap((formStepper) => formStepper.activeSlideIndex$),
  );

  private embeddedCheckout?: StripeEmbeddedCheckout;

  readonly form = new FormGroup({
    productId: new FormControl<string | null>(null, Validators.required),
  });

  readonly swiperOptions: SwiperOptions = {
    speed: 300,
  };

  protected readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;

  readonly state = connectState({
    options: this.billingService.subscriptions$.pipe(
      map(
        (subscriptions): Array<SelectionListOption<string>> =>
          subscriptions.map((subscription) => {
            const interval = subscription.recurring.interval;
            const intervalCount = subscription.recurring.interval_count;

            const translationKey = `billing.recurrence.${interval}`;
            const translatedRecurrence = this.internationalizationService.translate(translationKey, {
              count: intervalCount,
            });
            const price = this.currencyPipe.transform(
              subscription.unit_amount / 100 / intervalCount,
              subscription.currency.toUpperCase(),
            );

            return {
              label: `${translatedRecurrence}`,
              labelAdditional: `${price ? `${price}/${interval}` : ''}`,
              value: subscription.lookup_key,
              icon: 'check',
            };
          }),
      ),
    ),
  });

  ngOnDestroy() {
    this.embeddedCheckout?.destroy();
  }

  dismiss() {
    this.billingStepperDialogService.dismiss({ subscribed: false });
  }

  confirmFn: FormConfirmFn<typeof this.form> = () => {
    this.billingStepperDialogService.dismiss({ subscribed: true });
  };

  onCheckoutComplete() {
    this.formStepperRef()
      ?.goNextOrSubmit()
      .pipe(
        tap(() => {
          this.formStepperRef()?.swiperComponent()?.updateOption('allowSlidePrev', false);
          this.formStepperRef()?.swiperComponent()?.updateOption('allowSlideNext', false);
        }),
        switchMap(() => this.billingService.fetchActiveSubscription(this.authService.currentUser!.id)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private mountEmbeddedCheckoutFormWhenSelectedSubscriptionChanges() {
    return this.form.controls.productId.valueChanges.pipe(
      map((values) => values?.[0]),
      filter(isNotNullish),
      distinctUntilChanged(),
      switchMap((priceId) =>
        this.billingService.fetchEmbeddedCheckoutSessionClientSecret(priceId).pipe(catchError(() => of(null))),
      ),
      filter(Boolean),
      switchMap((clientSecret) => {
        if (this.embeddedCheckout) {
          this.embeddedCheckout.destroy();
          this.embeddedCheckout = undefined;
        }
        return this.stripeService.stripe.getInstance().initEmbeddedCheckout({
          clientSecret,
          onComplete: () => {
            this.onCheckoutComplete();
          },
        });
      }),
      tap((embeddedCheckout) => {
        this.embeddedCheckout = embeddedCheckout;
        embeddedCheckout.mount('#embedded-checkout');
      }),
    );
  }
}
