import { Injectable } from '@angular/core';
import { DateTimeService } from '@examdojo/core/date-time';
import { mapToVoid } from '@examdojo/core/rxjs';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DurationUnit } from 'date-fns/types';
import { BehaviorSubject, finalize, map, Observable, tap } from 'rxjs';
import { BillingHttpService } from './billing-http.service';
import { BillingHttpModel, BillingSubscription } from './billing.model';

const INTERVAL_TO_DURATION_UNIT: Record<BillingHttpModel['interval'], DurationUnit> = {
  DAY: 'days',
  WEEK: 'weeks',
  MONTH: 'months',
  YEAR: 'years',
};

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BillingService {
  constructor(
    private readonly billingHttpService: BillingHttpService,
    private readonly dateTimeService: DateTimeService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  readonly activeSubscription$$ = new BehaviorSubject<BillingHttpModel | null>(null);
  readonly activeSubscription$ = this.activeSubscription$$.asObservable().pipe(
    map((subscription): (BillingHttpModel & { nextPaymentDate?: Date }) | null => {
      if (!(subscription && subscription.start_date)) {
        return subscription;
      }

      const nextPaymentDate = this.dateTimeService.add(subscription.start_date, {
        [INTERVAL_TO_DURATION_UNIT[subscription.interval]]: subscription.interval_count,
      });

      return {
        ...subscription,
        nextPaymentDate,
      };
    }),
  );

  readonly checkoutSessionLoading$$ = new BehaviorSubject(false);
  readonly checkoutSessionLoading$ = this.checkoutSessionLoading$$.asObservable();

  readonly customerPortalLoading$$ = new BehaviorSubject(false);
  readonly customerPortalLoading$ = this.customerPortalLoading$$.asObservable();

  private readonly checkoutSessionClientSecret$$ = new BehaviorSubject<string | null>(null);
  readonly checkoutSessionClientSecret$ = this.checkoutSessionClientSecret$$.asObservable();

  private readonly subscriptions$$ = new BehaviorSubject<BillingSubscription[]>([]);
  readonly subscriptions$ = this.subscriptions$$.asObservable();

  readonly userHasProPlan$ = this.activeSubscription$.pipe(
    map(
      (subscription) =>
        /*
            Per db design, there is no identification of free plan in a table. If any record exists in the user_subscriptions table, it means the user has a pro plan.
        */
        isNotNullish(subscription) &&
        isNotNullish(subscription.start_date) &&
        (isNotNullish(subscription.end_date) ? this.dateTimeService.isAfter(subscription.end_date, new Date()) : true),
    ),
    map((hasProPlan) => !!hasProPlan),
  );

  fetchActiveSubscription(userId: string): Observable<void> {
    return this.billingHttpService.getActiveSubscription(userId).pipe(
      tap((subscription) => {
        this.activeSubscription$$.next(subscription);
      }),
      mapToVoid(),
    );
  }

  fetchCustomerPortalUrl(): Observable<string> {
    this.customerPortalLoading$$.next(true);
    return this.billingHttpService.getCustomerPortalSession().pipe(
      map((sessionUrl) => sessionUrl.result.link),
      finalize(() => this.customerPortalLoading$$.next(false)),
    );
  }

  fetchHostedCheckoutSessionUrl(): Observable<string> {
    this.checkoutSessionLoading$$.next(true);
    return this.billingHttpService.getHostedCheckoutSession().pipe(
      map((sessionUrl) => sessionUrl.result.link),
      finalize(() => this.checkoutSessionLoading$$.next(false)),
    );
  }

  fetchEmbeddedCheckoutSessionClientSecret(priceKey?: string): Observable<string> {
    this.checkoutSessionLoading$$.next(true);
    return this.billingHttpService.getEmbeddedCheckoutSession(priceKey).pipe(
      map((sessionUrl) => sessionUrl.result.client_secret),
      tap((clientSecret) => this.checkoutSessionClientSecret$$.next(decodeURIComponent(clientSecret))),
      finalize(() => this.checkoutSessionLoading$$.next(false)),
    );
  }

  fetchSubscriptions(): Observable<void> {
    return this.billingHttpService.fetchSubscriptions().pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.subscriptions' }),
      map((subscriptions) => subscriptions.filter((subscription) => isNotNullish(subscription.lookup_key))),
      tap((subscriptions) => this.subscriptions$$.next(subscriptions)),
      mapToVoid(),
    );
  }
}
