import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { FormatDatePipe } from '@examdojo/core/date-time';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag, ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { ButtonComponent } from '@examdojo/ui/button';
import { TranslocoPipe } from '@jsverse/transloco';
import { catchError, EMPTY, tap } from 'rxjs';
import { BillingStepperDialogService } from './billing-stepper/billing-stepper-dialog.service';
import { BillingService } from './billing.service';

@Component({
  selector: 'dojo-billing-info',
  imports: [ButtonComponent, FormatDatePipe, TranslocoPipe],
  templateUrl: './billing.component.html',
  styleUrl: './billing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingInfoComponent {
  constructor(
    private readonly billingService: BillingService,
    private readonly destroyRef: DestroyRef,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly billingStepperDialogService: BillingStepperDialogService,
    private readonly featureFlagService: FeatureFlagService<ExamdojoFeatureFlags>,
  ) {
    this.billingService
      .fetchSubscriptions()
      .pipe(
        catchError(() => EMPTY),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly state = connectState({
    userHasProPlan: this.billingService.userHasProPlan$,
    customerPortalLoading: this.billingService.customerPortalLoading$,
    checkoutSessionLoading: this.billingService.checkoutSessionLoading$,
    activeSubscription: this.billingService.activeSubscription$,
    useBillingStepper: this.featureFlagService.select(ExamDojoFeatureFlag.Billing),
  });

  readonly features = [
    { imagePath: 'assets/images/billing/unlimited.svg', text: 'settings.features.unlimited_questions' },
    { imagePath: 'assets/images/sidebar/coin-unlimited.svg', text: 'settings.features.unlimited_grading' },
    { imagePath: 'assets/images/billing/faster-grading.svg', text: 'settings.features.faster_grading' },
    { imagePath: 'assets/images/billing/no-ads.svg', text: 'settings.features.ad_free' },
  ];

  openProPlanCheckout() {
    this.billingService
      .fetchHostedCheckoutSessionUrl()
      .pipe(
        tap((checkoutUrl) => {
          window.location.href = checkoutUrl;
        }),
        this.errorHandlerService.catchError('[BillingComponent]: Failed to generate checkout session', () => EMPTY, {
          // TODO: refactor to support granular error handling
          toast: 'Failed to open checkout session',
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  openCustomerPortal() {
    this.billingService
      .fetchCustomerPortalUrl()
      .pipe(
        tap((portalUrl) => {
          window.location.href = portalUrl;
        }),
        this.errorHandlerService.catchError(
          '[BillingComponent]: Failed to generate customer portal session',
          () => EMPTY,
          {
            // TODO: refactor to support granular error handling
            toast: 'Failed to open customer portal',
          },
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  openBillingStepperDialog() {
    this.billingStepperDialogService.openDialog();
  }
}
