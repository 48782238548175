import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExamDojoFeatureFlag } from '@examdojo/models/feature-flag';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { IonHeader, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { BillingInfoComponent } from '../billing.component';

@Component({
  selector: 'dojo-billing-error-handling-dialog',
  template: `
    <ion-header [translucent]="true">
      <ion-toolbar class="toolbar-with-closing-button !px-2 md:!px-4">
        <div slot="start" class="flex">
          <dojo-icon-button (click)="closeDialog()" [icon]="'times'"></dojo-icon-button>
        </div>
        <div slot="end" class="w-8"></div>
        <ion-title class="common-ion-title">
          {{ 'examdojo.billing_limit_reached' | transloco }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="flex flex-col px-4 pb-4 pt-4 md:px-6 md:pb-6">
      <div class="mb-6 text-sm text-stone-800">{{ errorMessage }}</div>
      <dojo-billing-info />
    </div>
  `,
  imports: [IconButtonComponent, TranslocoPipe, IonTitle, IonToolbar, IonHeader, BillingInfoComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingErrorHandlingDialogComponent {
  constructor(private readonly modalController: ModalController) {}

  @Input({ required: true }) errorMessage!: string;
  readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;

  closeDialog() {
    this.modalController.dismiss();
  }
}
